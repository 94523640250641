.completedContainer {
  display: flex !important;
  align-items: center;
  flex-direction: column;
  color: #F5F5F5;
  max-width: 1800px !important;
  min-height: 100vh;
  justify-content: center;
}

.completedContainer img {
  height: 292px;
  width: 290px;
}

.congratulationsText {
  font-weight: 500 !important;
  font-size: 96px !important;
  text-align: center !important;
  width: 80vw;
}

.passedText {
  font-weight: 400 !important;
  font-size: 36px !important;
  margin-bottom: 10px !important;
}

.resultPaper {
  border-radius: 10px;
  padding: 60px;
}

.resultText {
  font-weight: 700 !important;
  font-size: 128px !important;
  text-align: center;
  margin-top: -50px !important;
}

.resultTextLong {
  font-weight: 700 !important;
  font-size: 64px !important;
  text-align: center;
  margin-top: -50px !important;
}

.infoContainer {
  flex-direction: row;
  display: flex;
  justify-content: center;
  margin-top: 5rem;
  width: 500px;
}

.icon {
  display: block;
  width: 123px !important;
  height: 123px !important;
  position: relative !important;
  left: -60px;
  top: -75px;
  background-color: #373D3F;
  color: #FFC40A;
  border-radius: 54px;
}

.loader {
  display: flex !important;
  flex: 1;
}

.completedButton {
  background-color: #FFCD1D !important;
  color: black !important;
  margin: 60px 0 30px 0 !important;
  font-weight: 600 !important;
  font-size: 24px !important;
  width: 381px;
  height: 60px;
}

.completedQuestions {
  margin-bottom: 30px !important;
}

.link {
  color: #FFCD1D;
}

.footer {
  display: flex;
  position: fixed;
  left: 0;
  bottom: 0;
  height: 90px;
  width: 100vw;
  color: #F5F5F5;
  font-weight: 300 !important;
  font-size: 18px !important;
  padding: 30px 60px;
}