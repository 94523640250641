.appBar {
  height: 120px;
  background-color: #2F2E2C !important;
  color: #F5F5F5;
}

.toolbar {
  height: 120px;
}

.appBarAlert {
  background-color: #AD3030 !important;
}

.appBar img {
  width: 112px;
  height: 112px;
}

.testContainer {
  margin-top: 180px;
}

.loaderContainer {
  margin-top: 180px;
  display: flex !important;
  justify-content: center;
  height: 80vh;
}

.staticBackground {
  height: 100%;
  width: 100vw !important;
  position: fixed;
  display: flex !important;
  right: 0px;
  bottom: 81px;
  max-width: 100% !important;
  z-index: -1;
  background-repeat: no-repeat;
  background-color: #f7f4ef;
  background-position: bottom right;
}

.appBar .appBarText {
  font-weight: 400;
  font-size: 18px;
}

.appBar .appBarName {
  font-weight: 600;
  font-size: 28px;
}

.appBar .appBarDivider {
  width: 2px !important;
  height: 61px !important;
  background-color: #F5F5F5;
  align-self: center;
  margin-right: 25px;
}

.appBar .appBarClockContainer {
  display: flex;
  justify-content: flex-end;
  flex: 10;
}

.appBar .appBarClock {
  font-weight: 700;
  font-size: 36px;
}

.displayFlexColumn {
  display: flex;
  flex-direction: column !important;
}

.lightBackground {
  max-width: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  height: max-content;
  min-height: 100vh;
  padding-bottom: 90px;
  background-color: #f7f4ef;
  z-index: 1;
}

.testInfoText {
  font-weight: 500 !important;
  font-size: 28px !important;
  margin: 30px 0 !important;
}

.questionContainer {
  border-radius: 10px !important;
  margin: 60px 0;
}

.nextButton {
  background-color: #FFCD1D !important;
  color: black !important;
  font-weight: 600 !important;
  font-size: 24px !important;
  height: 59px;
  align-self: center;
  margin: 0px auto !important;
}

.loader {
  align-self: center;
}

.footer {
  display: flex;
  position: fixed;
  left: 0;
  bottom: 0;
  height: 90px;
  width: 100vw;
  color: #F5F5F5;
  padding: 30px 60px;
  background-color: #2F2E2C;
}

.copyText {
  position: absolute;
  font-weight: 300 !important;
  font-size: 18px !important;
}