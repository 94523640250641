.displayFlex {
  display: flex;
}

.questionNumber {
  background-color: #373D3F;
  padding: 0.5rem 1rem;
  color: #F5F5F5;
  border-radius: 10px 0 0 0;
  font-weight: 700 !important;
  font-size: 20px !important;
}

.questionTitle {
  display: flex;
  padding: 0.5rem 1rem;
  align-items: baseline;
  background-color: #D9D9D9;
  width: 100%;
  font-weight: 400 !important;
  font-size: 20px !important;
  border-radius: 0px 10px 0px 0px;
}

.questionTitleText {
  font-weight: 400 !important;
  font-size: 20px !important;
}

.questionTitleBoldText {
  font-size: 20px !important;
}

.questionBody {
  display: flex;
  padding: 0 70px 70px 70px;
  align-items: center;
}

.singleChoiceQuestionBody {
  display: flex;
  align-items: center;
  padding: 0 70px 70px 70px;
}

.questionBodyHeader {
  padding: 70px 70px 20px;
  font-weight: 500 !important;
  font-size: 28px !important;
}

.multipleChoiceTitle {
  padding: 70px 70px 0;
  font-weight: 500 !important;
  font-size: 28px !important;
}

.multipleChoiceLowerPaddings {
  padding: 0 70px 70px 70px;
}

.fillTheGapsBody {
  display: flex;
  padding: 70px;
  align-items: center;
  flex-wrap: wrap;
}

.fillTheGapsText {
  font-weight: 500 !important;
  font-size: 28px !important;
}

.selectedText span {
  font-weight: 500 !important;
  font-size: 28px !important;
  color: #66C8FF !important;
}

.fillTheGapsText span {
  font-weight: 500 !important;
  font-size: 28px !important;
}

.fillTheGapsInput {
  font-weight: 500 !important;
  font-size: 28px !important;
}

.singleChoiceRadio {
  margin: 10px 10px 10px 0 !important;
}