.displayBlock {
  display: block !important;
}

.displayFlexColumn {
  display: flex;
  flex-direction: column;
}

.displayFlex {
  display: flex;
}

.displayFlexWrap {
  display: flex;
  flex-wrap: wrap;
}

.loader {
  align-self: center;
}

.error {
  color: red;
}