.logo {
  height: 292px;
  width: 290px;
  align-self: center;
}

.logoSmall {
  height: 101px;
  width: 100px;
  align-self: center;
}