.infoPaper {
  width: 500px;
  margin: 0 1.5rem;
  border-radius: 10px !important;
  height: 240;
}

.infoBlockText {
  color: black;
  margin-top: -80px;
  padding: 2rem;
  text-align: left;
}