.container {
  text-align: center;
  display: flex !important;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  color: white;
  font-family: "Roboto";
  font-size: 28px;
}

.button {
  align-self: center;
  background-color: #FFCD1D !important;
  color: black !important;
  font-family: "Roboto";
  font-weight: 600 !important;
  font-size: 24px !important;
  width: 314px;
  height: 59px;
}

.title {
  font-size: 48px;
  font-weight: 700 !important;
}

.copyText {
  font-weight: 300 !important;
  font-size: 18px !important;
  display: flex;
  position: fixed;
  left: 0;
  bottom: 0;
  height: 90px;
  width: 100vw;
  color: #F5F5F5;
  padding: 30px 60px;
  z-index: -1;
}