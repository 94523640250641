.hideCancelButton {
  display: none !important;
}

.confirmTitle {
  background-color: #2F2E2C;
  color: #F5F5F5;
  text-align: center;
}

.confirmBody {
  text-align: center;
  font-weight: 400 !important;
  font-size: 28px !important;
  padding: 70px 70px 0px 70px !important;
}

.dialogActions {
  justify-content: center;
}