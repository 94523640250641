.container {
  display: flex !important;
  flex-direction: column;
  height: 100%;
  color: black;
  font-family: "Roboto";
}

.termsContainer {
  padding: 3rem;
  background-color: #F5F5F5;
  margin: 81px 0px;
}

.termsFooter {
  position: fixed;
  bottom: 0px;
  display: flex;
  justify-content: center;
  padding: 1rem;
  background-color: #FFFFFF;
  max-width: 1103px;
  width: 100%;
  border-color: #e0e0e0;
  border-width: 1px 0px 0px 0px;
  border-style: solid;
  height: 82px;
}

.termsFooterText {
  align-self: center;
}

.termsFooterText:hover {
  font-size: 24px;
  cursor: pointer;
}

.termsAccepted {
  padding-right: 1rem;
  padding-top: 4px;
}

.button {
  background-color: #FFC40A !important;
  color: black !important;
  padding: 0 1rem !important;
}

.paragraph {
  margin-bottom: 25px !important;
}

.header {
  position: fixed;
  display: flex;
  flex-direction: column !important;
  max-width: 1103px;
  width: 100%;
  background-color: #FFFFFF;
}

.copyText {
  font-weight: 300 !important;
  font-size: 18px !important;
  display: flex;
  position: fixed;
  left: 0;
  bottom: 0;
  height: 90px;
  width: 100vw;
  color: #F5F5F5;
  padding: 30px 60px;
  z-index: -1;
}