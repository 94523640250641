.container {
  text-align: center;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  color: white;
  font-family: "Roboto";
  height: 100%;
}

.infoContainer {
  flex-direction: row;
  display: flex;
  justify-content: center;
  margin-top: 48px;
}

.icon {
  display: block;
  width: 5rem !important;
  height: 5rem !important;
  position: relative !important;
  left: -250px;
  top: -2.5rem;
  background-color: #373D3F;
  border-radius: 2.5rem;
}

.button {
  align-self: center;
  background-color: #FFC40A !important;
  color: black !important;
  margin: 2rem !important;
}

.link {
  text-decoration: none;
  display: flex;
  align-self: center;
  margin-top: 32px;
}

.helloText {
  font-weight: 500;
  font-size: 20px !important;
  margin-bottom: 20px !important;
}

.helloHeader {
  font-weight: 500;
  font-size: 58px !important;
}

.helloSmallerText {
  font-size: 20px !important;
  margin-left: 20px !important;
}

.title {
  font-weight: 700 !important;
  margin-left: 20px !important;
}

.list {
  margin: 0 !important;
  padding: 0px 0px 0px 20px !important;
}

.footer {
  display: flex;
  position: fixed;
  left: 0;
  bottom: 0;
  height: 90px;
  width: 100vw;
  color: #F5F5F5;
  font-weight: 300 !important;
  font-size: 18px !important;
  padding: 30px 60px;
  z-index: -1;
}