html, body { height:100vh; margin:0; padding:0; }

body {
  background-color: #373D3F !important;
  font-size: 24px !important;
  font-weight: 400 !important;
  user-select: none;
}

.appContainer {
  height: 100vh;
}

@font-face {
  font-family: "Roboto";
  src: url("../public/Roboto-Regular.ttf") format("truetype");
}